<template>
  <ContactForm v-bind="$attrs" color="bluegiant-light-blue" v-on="$listeners">
    <template #success-msg="{ dismiss }">
      <h1 class="grey--text">Thank you.</h1>
      <p class="r-title">We'll get back to you soon.</p>
      <BaseButton
        class="ml-0"
        color="bluegiant-blue lighten-2"
        small
        @click="dismiss"
        >Sounds Good!</BaseButton
      >
    </template>
    <template #submit-btn
      ><BaseButton color="bluegiant-blue" class="ml-0" type="submit"
        >Send</BaseButton
      ></template
    >
  </ContactForm>
</template>

<script>
import ContactForm from '@components/ContactForm'

export default {
  name: 'BlueGiantContactForm',
  components: {
    ContactForm,
  },
  data() {
    return {}
  },
}
</script>
